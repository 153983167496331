import { useState } from 'react';
import useCage from 'contexts/cage-context';
import useGotoUrl from 'hooks/goto-url';
import useInterval from 'hooks/useInterval';
import moment from 'moment';
import {
    useReportsController_CreateReport,
    useReportsController_DeleteReport,
    useReportsController_ReportList,
} from 'services/hooks';
import batchServiceStatus from 'utils/batchServiceStatus';

import PageHeaderBar from '@/components/molecules/PageHeaderBar';
import { Loader } from 'components/atoms/Loader';

import CagePageTitle from '../../components/LocationsAndCagesNavigation';

import ReportList from './ReportList';
const REPORT_REFRESH_INTERVAL = 2000;

export function ReportListContainer() {
    const { cageId, timezone } = useCage();

    const gotoUrl = useGotoUrl();
    const [newReportError, setNewReportError] = useState(null);
    const {
        data,
        isLoading,
        error,
        refetch: refetchList,
    } = useReportsController_ReportList(cageId);
    const { mutateAsync: createReport } = useReportsController_CreateReport({
        onSuccess: () => refetchList(),
    });
    const { mutateAsync: deleteReport } = useReportsController_DeleteReport({
        onSuccess: () => refetchList(),
    });

    const reports =
        (data?.data ?? []).map(({ status, updatedAt, lastFeedDay, slaughterDay, ...report }) => ({
            ...report,
            updatedAt: moment(updatedAt).tz(timezone).toDate(),
            lastFeedDay: lastFeedDay ? moment(lastFeedDay).toDate() : null,
            slaughterDay: slaughterDay ? moment(slaughterDay).toDate() : null,
            status: batchServiceStatus({ status, updatedAt: new Date(updatedAt) })?.status,
            onDelete: () => {
                deleteReport({ cageId, reportId: report.id });
            },
            onClick: () => {
                (status === 'finished' || status === 'warning') && gotoUrl(`/${report.id}`);
            },
        })) ?? [];

    const anyReportIsRunning = reports.some(
        ({ status }) => status === 'started' || status === 'created'
    );
    useInterval(refetchList, anyReportIsRunning ? REPORT_REFRESH_INTERVAL : null);

    const input = {
        reports,
        onCreateReport: async (requestBody: {
            lastFeedDay: string;
            temperature: number | null;
            gotoDetail: boolean;
            slaughterDay?: string;
        }) => {
            setNewReportError(null);
            const cleanedRequestBody = {
                ...requestBody,
                lastFeedDay: moment(requestBody.lastFeedDay).format('YYYY-MM-DD'),
                slaughterDay: requestBody.slaughterDay
                    ? moment(requestBody.slaughterDay).format('YYYY-MM-DD')
                    : null,
            };
            try {
                const result = await createReport({
                    cageId,
                    requestBody: cleanedRequestBody,
                });
                result.data && gotoUrl(`/${result.data}`);
            } catch (e) {
                setNewReportError(e);
            }
        },
    };

    return (
        <>
            <PageHeaderBar>
                <CagePageTitle />
            </PageHeaderBar>
            <Loader loading={isLoading} error={error || newReportError}>
                <ReportList {...input} />
            </Loader>
        </>
    );
}
