import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import useCage from '@/contexts/cage-context';
import { useMe } from '@/contexts/meContext';

import Notice from './Notice';

interface NoticeContainerProps {
    enableNotice: boolean;
    noticeId: string;
    heading: string;
    message: any;
    showCloseButton?: boolean;
    variant: 'default' | 'warning';
    topic?: string;
    asToast?: boolean;
}

const NoticeContainer = ({
    enableNotice,
    noticeId,
    heading,
    message,
    showCloseButton,
    variant,
    topic,
    asToast = true,
}: NoticeContainerProps) => {
    const [showNotice, setShowNotice] = useState(() => {
        const isNoticeHidden = localStorage.getItem(`isNotice${noticeId}Hidden`);
        if (!enableNotice) {
            // If enableNotice is false, remove the flag
            localStorage.removeItem(`isNotice${noticeId}Hidden`);
            return false;
        }
        if (isNoticeHidden === 'true') {
            return false;
        }
        return enableNotice;
    });

    const location = useLocation();

    console.log(location);

    const { state } = useMe();
    const { hasCageLicense, client, licenses } = useCage();

    const hasLicense =
        licenses !== undefined && licenses?.length > 0
            ? licenses?.some((license) => {
                  return license.code === topic;
              })
            : false;

    const handleCloseNotice = () => {
        localStorage.setItem(`isNotice${noticeId}Hidden`, 'true');
        setShowNotice(false);
    };

    useEffect(() => {
        if (asToast && showNotice && hasLicense) {
            toast(
                <Notice
                    heading={heading}
                    message={message}
                    showCloseButton={showCloseButton}
                    onClose={handleCloseNotice}
                    variant={variant}
                    topic={topic}
                />,
                {
                    autoClose: false,
                    toastId: noticeId,
                    containerId: 'optoscale-toast',
                    position: 'bottom-right',
                    hideProgressBar: false,
                    pauseOnHover: true,
                    closeOnClick: true,
                    onClose: () => {
                        console.log('Notice closed');
                        handleCloseNotice();
                    },
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    });

    return (
        <>
            {showNotice && !asToast && (
                <Notice
                    heading={heading}
                    message={message}
                    showCloseButton={showCloseButton}
                    onClose={handleCloseNotice}
                    variant={variant}
                    topic={topic}
                />
            )}
        </>
    );
};

export default NoticeContainer;
export { NoticeContainer };
