import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { AllWeeklyReportResult } from 'services/types';

import LiceIcon from '@/components/atoms/icons/LiceIcon';
import PageHeaderBar from '@/components/molecules/PageHeaderBar';
import { Page, PageContent, PageTitle } from 'components/Layout';
import NoData from 'components/NoData';

import { LocationContainer } from './Locations/LocationContainer/LocationContainer';

import styles from './LiceReport.module.scss';

interface OverviewProps {
    clientId: number;
    clientName: string;
    reports: AllWeeklyReportResult[];
}

const LiceReport = ({ reports, clientId, clientName }: OverviewProps) => {
    const { t } = useTranslation();
    if (reports.length === 0) {
        return (
            <Page title={t('Lice report')}>
                <PageContent>
                    <NoData customText={'No locations'} />
                </PageContent>
            </Page>
        );
    }

    if (reports.length === 1 && reports[0].locations.length === 0) {
        return (
            <Page title={t('Lice report')}>
                <PageContent>
                    <NoData customText={'No Report'} />
                </PageContent>
            </Page>
        );
    }

    return (
        <Page title={t('Lice report')}>
            <PageContent>
                <Container fluid>
                    <div className={styles.liceReportPage}>
                        <PageHeaderBar>
                            <PageTitle title={t('Lice report')} showH1={true}>
                                <LiceIcon />
                            </PageTitle>
                        </PageHeaderBar>

                        {reports.map((report, index) => (
                            <LocationContainer
                                key={report.isoWeek.toString()}
                                weekNumber={`${t('Week')} ${report.isoWeek}, ${report.isoYear}${
                                    report.interimReport ? ` - ${t('Interim Report')}` : ''
                                }`}
                                locations={report.locations}
                                openDefault={index === 0}
                                clientName={clientName}
                                clientId={clientId}
                            />
                        ))}
                    </div>
                </Container>
            </PageContent>
        </Page>
    );
};

export default LiceReport;
