import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import * as features from 'config/features';
import { BioscopeDetailContainer } from 'containers/BioscopeDetailContainer';
import { useMe } from 'contexts/meContext';

import { PageTitleForAnalytics } from '@/components/Layout/PageTitle/PageTitle';
import StickyAlert from '@/components/molecules/StickyAlert';
import { useUserController_EndImpersonate } from '@/services/hooks';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Buttons';
import { HowMuchIsTheFish } from 'components/molecules/HowMuchIsTheFish';
import { HeaderLink } from 'components/organisms/Header';
import HeaderContainer from 'components/organisms/Header/HeaderContainer';
import styles from 'components/organisms/Header/HeaderLink/headerLink.module.scss';

import UserManagementPage from '../../components/UserManagmentPage/UserPage';

import { CompareContainer } from './components/Compare';
import HelpPageContainer from './components/Help/HelpPageContainer';
import { MoveBioscopeContainer } from './MoveBioScope/MoveBioscopeContainer';
import LiceReport from './Overview/LiceReport';
import TabularOverview from './Overview/TabularOverview2';
import { CageContainer } from './Cage';

const Client = ({ match }) => {
    const baseUrl = match.url;
    const location = useLocation();

    const queryClient = useQueryClient();

    const { mutateAsync: endImpersonate } = useUserController_EndImpersonate({
        onSuccess: () => {
            queryClient.invalidateQueries();
            window.location.replace('/');
        },
    });
    const { state, hasUserFeature, isClientAdmin, isOptoscaleAdmin } = useMe();
    const { t } = useTranslation();

    const client = state.clients.find((client) => client.id === parseInt(match.params.clientId));

    useEffect(() => {}, [state]);

    // Create a new function for the onClick event
    const handleSwitchBackToOwnAccount = async (e) => {
        e.preventDefault();
        await endImpersonate();
    };

    if (!client) {
        return <Alert color="danger">{t('Unknown client')}</Alert>;
    }

    const canMoveBioscopes = isOptoscaleAdmin || isClientAdmin(parseInt(match?.params?.clientId));
    const isImpersonator = state?.user?.impersonatorUser?.id;
    return (
        <>
            {isImpersonator && (
                <StickyAlert position="top">
                    <Alert color="neutral" size="small" contentAlign="center">
                        <span>
                            <b>{`${state.user?.impersonatorUser?.firstName} ${state.user?.impersonatorUser?.lastName}`}</b>
                            {' you are acting as '}
                            <b>{`${state.user?.firstName} ${state.user?.lastName}`}</b>.
                        </span>
                        <Button size="small" onClick={handleSwitchBackToOwnAccount}>
                            Switch to your own account
                        </Button>
                    </Alert>
                </StickyAlert>
            )}

            <HeaderContainer>
                <Nav className={styles.menuWrapper}>
                    <HeaderLink iconName="overviewIcon" to={`${baseUrl}/cages/v3`}>
                        {t('Site overview')}
                    </HeaderLink>

                    <HeaderLink iconName="moveIcon" to={`${baseUrl}/move-bioscope`}>
                        {canMoveBioscopes ? t('Move bioscope') : t('Bioscopes')}
                    </HeaderLink>

                    <HeaderLink iconName="userIcon" to={`${baseUrl}/users`}>
                        {t('Users')}
                    </HeaderLink>
                    <HeaderLink iconName="helpIcon" to={`${baseUrl}/help`}>
                        {t('Help')}
                    </HeaderLink>
                    {/*  <HelpMenuLink baseUrl={baseUrl} /> */}
                </Nav>
            </HeaderContainer>

            <Switch>
                <Route
                    exact
                    path={`${match.path}/help`}
                    component={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title="Help" />
                                <HelpPageContainer />
                            </>
                        );
                    }}
                />
                <Route
                    exact
                    path={`${match.path}/bioscope/:bioscopeId`}
                    component={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title="Help" />
                                <BioscopeDetailContainer />
                            </>
                        );
                    }}
                />
                <Route
                    exact
                    path={match.path}
                    render={() => <Redirect to={`${baseUrl}/cages/v3${location.search}`} />}
                />
                <Route
                    exact
                    path={`${match.path}/cages/v2`}
                    render={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title={'Site overview'} />
                                <Redirect to={`${match.path}/cages/v3`} />
                            </>
                        );
                    }}
                />

                <Route
                    exact
                    path={`${match.path}/cages/v3`}
                    render={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title={'Site overview'} />
                                <TabularOverview clientId={client.id} />
                            </>
                        );
                    }}
                />

                <Route
                    exact
                    path={`${match.path}/cages/lice-report`}
                    render={({ match }) => (
                        <>
                            <PageTitleForAnalytics category="global" title="Lice report" />
                            <LiceReport
                                clientId={parseInt(match?.params?.clientId)}
                                clientName={client?.name}
                            />
                        </>
                    )}
                />

                <Route
                    exact
                    path={`${match.path}/cages/alternative`}
                    render={() => <Redirect to={`${match.path}/cages/move-bioscope`} />}
                />

                <Route
                    path={`${match.path}/cages/v1/:cageId`}
                    render={({ match }) => (
                        <Redirect
                            to={`${baseUrl}/cages/v2/${match.params?.cageId}${location.search}`}
                        />
                    )}
                />
                <Route
                    path={`${match.path}/cages/v2/:cageId`}
                    render={({ match }) => (
                        <CageContainer cageId={parseInt(match?.params?.cageId)} />
                    )}
                />
                <Route
                    path={`${match.path}/cages/v3/:cageId`}
                    render={({ match }) => (
                        <CageContainer cageId={parseInt(match?.params?.cageId)} />
                    )}
                />
                <Route
                    path={`${match.path}/cages/v3-new/:cageId`}
                    render={({ match }) => (
                        <Redirect to={`${baseUrl}/cages/v3/${match.params.cageId}`} />
                    )}
                />
                {/* <Route
                    path={match.path + '/compare'}
                    component={cProps => (
                        <CageContainer cageId={parseInt(match?.params?.cageId)} />
                    )}
                /> */}
                <Route
                    exact
                    path={`${match.path}/compare`}
                    component={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title="Compare" />
                                <CompareContainer clientId={client.id} />
                            </>
                        );
                    }}
                />
                <Route
                    path={`${match.path}/cages/alternative/:cageId`}
                    render={({ match }) => (
                        <Redirect
                            to={`${baseUrl}/cages/${match.params?.cageId}${location.search}`}
                        />
                    )}
                />
                <Route
                    path={`${match.path}/cages/:cageId`}
                    render={({ match }) => {
                        return (
                            <Redirect
                                to={`${baseUrl}/cages/v2/${match.params?.cageId}${location.search}`}
                            />
                        );
                    }}
                />
                <Route
                    path={`${match.path}/users`}
                    component={(cProps) => (
                        <>
                            <PageTitleForAnalytics title="Users" category="global" />
                            <UserManagementPage
                                {...cProps}
                                clientId={parseInt(cProps.match.params.clientId)}
                            />
                        </>
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/move-bioscope`}
                    component={(cProps) => (
                        <>
                            <PageTitleForAnalytics title="Move bioscope" category="global" />
                            <MoveBioscopeContainer
                                {...cProps}
                                clientId={parseInt(cProps.match.params.clientId)}
                            />
                        </>
                    )}
                />
                <Redirect to={baseUrl + location.search} />
            </Switch>

            {hasUserFeature(features.HOW_MUCH_IS_THE_FISH) && <HowMuchIsTheFish />}
        </>
    );
};

export default Client;
