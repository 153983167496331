import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';

import { Loader } from '@/components/atoms/Loader';
import SomethingHappened from '@/components/SomethingHappened';
import { useClientController_Compare, useClientController_Overview } from '@/services/hooks';
import { OverviewLocation } from '@/services/types';
import { LeftColumn, Page, PageContent } from 'components/Layout';

import CageFilterCompare from '../../Cage/components/CageFilterCompare';

import CompareContentPage from './CompareContentPage';

import styles from './CompareContainer.module.scss';

const getUrlParameters = (search: string) => {
    const params = new URLSearchParams(search);
    const dataParam = params.get('data');
    if (!dataParam) {
        return [];
    }
    const dataArray = dataParam.split(',');
    const result = dataArray.reduce((result, item) => {
        const [key, value] = item.split('~');
        result[value] = key;
        return result;
    }, {});
    return result;
};

const CompareContainer = ({ clientId }: { clientId: number }) => {
    const [urlData, setUrlData] = useState({});
    const [submittedData, setSubmittedData] = useState<{
        from?: string;
        to?: string;
        daypartTag?: string;
    }>({ from: null, to: null, daypartTag: 'day' });

    const history = useHistory();

    const {
        isLoading: isLoadingClientLocations,
        data,
        error,
        refetch,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const url = window.location.search;
    const urlDataFromParams = getUrlParameters(url);
    const cageIdsFromParams = Object.keys(urlDataFromParams).map(Number);

    const {
        data: compareData,
        isLoading: compareIsLoading,
        error: compareError,
    } = useClientController_Compare({
        cages: cageIdsFromParams,
        from: submittedData?.from,
        to: submittedData?.to,
        daypartTag: submittedData?.daypartTag || 'day',
    });

    useEffect(() => {
        const url = window.location.search;
        setUrlData(getUrlParameters(url));
    }, []);

    useEffect(() => {
        const url = window.location.search;
        setUrlData(getUrlParameters(url));
    }, [window.location.search]);

    const handleFormSubmit = useCallback((from: string, to: string, daypartTag: string) => {
        setSubmittedData({ from, to, daypartTag });
    }, []);

    if (compareIsLoading || isLoadingClientLocations) {
        return <Loader />;
    }

    if (error || compareError) {
        return <SomethingHappened />;
    }

    return (
        <Page title={'Compare'}>
            <LeftColumn>
                <nav className={styles.leftNavigationPanel}>
                    <NavbarBrand tag={Link} to="/">
                        <figure>
                            <img src="/logo.png" alt="" />
                        </figure>
                    </NavbarBrand>
                    <CageFilterCompare onFormSubmit={handleFormSubmit} />
                </nav>
            </LeftColumn>
            <PageContent>
                <CompareContentPage
                    locations={data?.data?.locations as OverviewLocation[]}
                    graphData={compareData?.data}
                    urlData={urlData}
                    submittedData={submittedData}
                    clientId={clientId}
                    history={history}
                />
            </PageContent>
        </Page>
    );
};

export default CompareContainer;
export { CompareContainer };
