import React from 'react';
import { toast } from 'react-toastify';

export interface ToastTemplateProps {
    httpStatusCode?: number;
    toastId?: string;
    content: string | React.ReactNode;
    position?:
        | 'top-left'
        | 'top-center'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-center'
        | 'bottom-right';
}

const ToastTemplate = ({
    httpStatusCode = 200,
    toastId = 'default',
    content,
    position = 'bottom-right',
}: ToastTemplateProps) => {
    if (httpStatusCode === 200 || httpStatusCode === 201) {
        toast.warn(content, {
            toastId: toastId,
            containerId: 'optoscale-toast',
            position: position,
            autoClose: 8000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.error('Failed to submit feedback', {
            toastId: toastId,
            containerId: 'optoscale-toast',
            position: position,
            autoClose: 8000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return <></>;
};

export default ToastTemplate;
export { ToastTemplate };
